<!-- footer start -->
<footer class="footer">
    <div class="footer_top img-fluid">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-md-6 col-lg-4">
                    <div class="footer_widget">
                        <div class="footer_logo">
                            <a [routerLink]="['/']">
                                <img class="Corea y Asociados Corasco" src="assets/img/logo.webp" height="130" width="200" alt="">
                            </a>
                        </div>
                        <p class="color">
                            Corea y Asociados S.A.(Corasco), Ingenieros Consultores, Diseñadores y Planificadores, Fundada el 6 de Julio de 1991.
                        </p>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6 col-lg-2">
                    <div class="footer_widget">
                        <h3 class="footer_title color">
                            Servicios
                        </h3>
                        <ul>
                            <li><a class="cursor color" [routerLink]="['/servicios/diseño']">Diseño</a></li>
                            <li><a class="cursor color" [routerLink]="['/servicios/estudios']">Estudios</a></li>
                            <li><a class="cursor color" [routerLink]="['/servicios/direccion']">Dirección, Administración y Supervisión de Proyectos</a></li>
                            <li><a class="cursor color" [routerLink]="['/servicios/laboratorio']">Laboratorio</a></li>
                            <li><a class="cursor color" [routerLink]="['/servicios/topografia']">Topografia</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 col-lg-3">
                    <div class="footer_widget" *ngFor="let informacion of datos">
                        <h3 class="footer_title">
                            Dirección
                        </h3>
                        <p class="color">
                            {{informacion.data.Direccion}}
                        </p>
                        <br>
                        <h3 class="footer_title">
                            Horario de Atención
                        </h3>
                        <p class="color">
                            <i class="fas fa-calendar-week color"></i> {{informacion.data.Horario_Semanal}}
                            <br>
                            <i class="fas fa-calendar-week color"></i> {{informacion.data.Horario_FinSemana}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copy-right_text">
        <div class="container">
            <div class="footer_border"></div>
            <div class="row">
                <div class="col-xl-12">
                    <p class="copy_right text-center foot">
                        &copy; 2021 Corea y Asociados S.A | Desarrollado por Área Informática
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer end  -->
