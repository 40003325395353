import { Component, OnInit } from '@angular/core';
import { InformacionService } from '../../services/informacion.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public data = [];
  public status: any;
  constructor(private informacionService: InformacionService ) { }

  ngOnInit(): void {
    this.cargarData();
  }

  public cargarData() {
    this.informacionService.dataPaginaInicio().subscribe((resp: any) => {
      this.data = [];
      resp.forEach((data: any) => {
        this.data.push({
          id: data.payload.doc.id,
          data: data.payload.doc.data()
        });
      });
      this.status = this.data;
    });
  }

}
