<div class="header-top_area">
    <div class="container">
        <div class="row" *ngFor="let informacion of data">
            <div class="col-xl-6 col-md-6 redes">
                <div class="social_media_links animated fadeInLeftBig cursor" *ngIf="informacion.data.Estado">
                    <a [href]="informacion.data.linkedin" target="_blank">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                    <a [href]="informacion.data.facebook" target="_blank">
                        <i class="fa fa-facebook-official" aria-hidden="true"></i>
                    </a>
                    <a [href]="informacion.data.instagram" target="_blank">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
            <div class="col-xl-6 col-md-6">
                <div class="short_contact_list social_media_links animated fadeInRightBig cursor">
                    <ul>
                        <li>
                            <a href="mailto:{{informacion.data.correo}}?subject=Solicitud Información" style="text-decoration: none"> 
                                <i class="fa fa-envelope color"></i>{{informacion.data.correo}}
                            </a>
                        </li>
                        <li>
                            <a > <i class="fa fa-phone color"></i>{{informacion.data.Telefono}}</a>
                        </li>
                        <li>
                            <a target="_blank" [href]="informacion.data.ubicacion" style="text-decoration: none"> 
                                <i class="fas fa-map-marker-alt color"></i> {{informacion.data.Direccion}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
