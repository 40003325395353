import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { QuicklinkModule } from 'ngx-quicklink';

import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [

  {
    path: 'inicio',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'galeria',
    loadChildren: () => import('./pages/galeria/galeria.module').then(m => m.GaleriaModule)
  },
  {
    path: 'servicios',
    loadChildren: () => import('./pages/servicio/servicio.module').then(m => m.ServicioModule)
  },
  {
    path: 'proyectos',
    loadChildren: () => import('./pages/proyecto/proyecto.module').then(m => m.ProyectoModule), data: {preload: true}
  },
  {
    path: 'contacto',
    component: ContactoComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'inicio'
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [QuicklinkModule, RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
