import { Component, OnInit } from '@angular/core';
import { InformacionService } from '../../services/informacion.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public datos = [];
  constructor(private informacionService: InformacionService) { }

  ngOnInit(): void {
    this.cargarDatos();
  }

  public cargarDatos() {
    this.informacionService.dataPaginaInicio().subscribe((resp: any) => {
      this.datos = [];
      resp.forEach((item: any) => {
        this.datos.push({
          id: item.payload.doc.id,
          data: item.payload.doc.data()
        });
      });
    });
  }
}
