// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCXu4rWewUyZzQYmDBFOg6YMXhqMA-ApPs',
    authDomain: 'website-corasco.firebaseapp.com',
    projectId: 'website-corasco',
    storageBucket: 'website-corasco.appspot.com',
    messagingSenderId: '675910055756',
    appId: '1:675910055756:web:d61b89230869b4ec8001e9',
    // measurementId: 'G-8T6SQHK0N4'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
