import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InformacionService } from '../../services/informacion.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  public contactoForm: FormGroup;
  public datos = [];
  constructor(private fb: FormBuilder, private toastr: ToastrService, private informacionService: InformacionService) { }

  ngOnInit(): void {
    this.contactoFormulario();
    this.cargarDatos();
  }

  cargarDatos() {
    this.informacionService.dataPaginaInicio().subscribe(resp => {
      this.datos = [];
      resp.forEach(item => {
        this.datos.push({
          id: item.payload.doc.id,
          data: item.payload.doc.data()
        });
      });
    });
  }

  contactoFormulario() {
    this.contactoForm = this.fb.group({
      nombre: ['', Validators.required],
      correo: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      telefono: ['', Validators.required],
      mensaje: ['', Validators.required]
    });
  }

  enviarEmail(data) {
    this.informacionService.createContacto(data).then(() => {
      this.toastr.success('Correo Enviado Exitosamente', 'Enviado');
      this.contactoForm.reset();
    }, (error) => {
      this.toastr.error(`${error}`, 'Error');
    });
  }

  get nombreNoValido(){
    return this.contactoForm.get('nombre').invalid && this.contactoForm.get('nombre').touched;
  }

  get nombreValido(){
    return this.contactoForm.get('nombre').valid;
  }

  get correoNoValido(){
    return this.contactoForm.get('correo').invalid && this.contactoForm.get('correo').touched;
  }

  get correoValido(){
    return this.contactoForm.get('correo').valid;
  }

  get telefonoNoValido(){
    return this.contactoForm.get('telefono').invalid && this.contactoForm.get('telefono').touched;
  }

  get telefonoValido(){
    return this.contactoForm.get('telefono').valid;
  }

  get mensajeNoValido(){
    return this.contactoForm.get('mensaje').invalid && this.contactoForm.get('mensaje').touched;
  }

  get mensajeValido(){
    return this.contactoForm.get('mensaje').valid;
  }

}
