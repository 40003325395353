export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCXu4rWewUyZzQYmDBFOg6YMXhqMA-ApPs',
    authDomain: 'website-corasco.firebaseapp.com',
    projectId: 'website-corasco',
    storageBucket: 'website-corasco.appspot.com',
    messagingSenderId: '675910055756',
    appId: '1:675910055756:web:d61b89230869b4ec8001e9',
    // measurementId: 'G-8T6SQHK0N4'
  }
};
