<!-- Navbar Begin -->
<header>
    <div class="header-area ">
        <!-- Header Begin -->
        <app-header></app-header>
        <!-- Header End -->
        <div id="sticky-header" class="main-header-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-3 col-lg-2">
                        <div class="logo animated fadeInUp">
                            <a [routerLink]="['/inicio']" routerLinkActive="active">
                                <img class="corasco" src="assets/img/logo.webp" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-7">
                        <div class="main-menu  d-none d-lg-block">
                            <nav>
                                <ul id="navigation">
                                    <li class="nav-item" routerLinkActive="active">
                                        <a class="nav-link" [routerLink]="['/inicio']">Inicio</a>
                                    </li>
                                    <li><a>Servicios <i class="fa fa-angle-down" aria-hidden="true"></i></a>
                                        <ul class="submenu">
                                            <li routerLinkActive="active">
                                                <a [routerLink]="['/servicios/diseño']">Diseño</a>
                                            </li>
                                            <li routerLinkActive="active">
                                                <a [routerLink]="['/servicios/estudios']">Estudios</a>
                                            </li>
                                            <li routerLinkActive="active">
                                                <a [routerLink]="['/servicios/direccion']">Dirección, Administración y Supervisión de Proyectos</a>
                                            </li>
                                            <li routerLinkActive="active">
                                                <a [routerLink]="['/servicios/laboratorio']">Laboratorio</a>
                                            </li>
                                            <li routerLinkActive="active">
                                                <a [routerLink]="['/servicios/topografia']">Topografia</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li routerLinkActive="active">
                                        <a class="nav-link cursor" [routerLink]="['/proyectos']" routerLinkActive="active"> Proyectos </a>
                                    </li>
                                    <li class="nav-item" routerLinkActive="active">
                                        <a class="nav-link" [routerLink]="['/galeria']">Galeria</a>
                                    </li>
                                    <li class="nav-item" routerLinkActive="active">
                                        <a class="nav-link" [routerLink]="['/contacto']">Contacto</a>
                                    </li>
                                    <!-- <li class="nav-item" routerLinkActive="active">
                                        <a class="nav-link" [routerLink]="['/prueba']">Prueba</a>
                                    </li> -->
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mobile_menu d-block d-lg-none"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Navbar-end -->
