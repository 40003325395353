import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root'
})
export class InformacionService {

  public listarProyectosMTI: any[] = [];
  public listarProyectosFOMAV: any[] = [];
  public listarProyectosMINSA: any[] = [];
  public listarProyectosALMA: any[] = [];

  public cargando: boolean = true;

  constructor(private firestore: AngularFirestore) { }

  public getGalerias() {
    return this.firestore.collection('galeria').snapshotChanges();
  }

  public createContacto(data) {
    return this.firestore.collection('contacto').add(data);
  }

  public dataPaginaInicio() {
    return this.firestore.collection('Pagina-Informacion').snapshotChanges();
  }

  cargarProyectoMTI() {
    this.listarProyectosMTI.length = 0;

    this.firestore.collection('proyectosMTI').get().subscribe((result) => {
         this.listarProyectosMTI = [];
         result.forEach((item: any) => {
         let inscripcionObtenida = item.data();
         inscripcionObtenida.id = item.id;

         ///TODO pedir informacion de un reference
         this.firestore.doc(item.data().categoria.path).get().subscribe((cliente) => {
              inscripcionObtenida.clienteObtenido = cliente.data();
              this.listarProyectosMTI.push(inscripcionObtenida);
              this.cargando = false;
           });
         });
    });
  }

  cargarProyectoFOMAV() {
    this.listarProyectosFOMAV.length = 0;

    this.firestore.collection('proyectosFOMAV').get().subscribe((result) => {
         this.listarProyectosFOMAV = [];
         result.forEach((item: any) => {
         let inscripcionObtenida = item.data();
         inscripcionObtenida.id = item.id;

         ///TODO pedir informacion de un reference
         this.firestore.doc(item.data().categoria.path).get().subscribe((cliente) => {
              inscripcionObtenida.clienteObtenido = cliente.data();
              this.listarProyectosFOMAV.push(inscripcionObtenida);
              this.cargando = false;
           });
         });
    });
  }

  cargarProyectoMINSA() {
    this.listarProyectosMINSA.length = 0;

    this.firestore.collection('proyectosMINSA').get().subscribe((result) => {
         this.listarProyectosMINSA = [];
         result.forEach((item: any) => {
         let inscripcionObtenida = item.data();
         inscripcionObtenida.id = item.id;

         ///TODO pedir informacion de un reference
         this.firestore.doc(item.data().categoria.path).get().subscribe((cliente) => {
              inscripcionObtenida.clienteObtenido = cliente.data();
              this.listarProyectosMINSA.push(inscripcionObtenida);
              this.cargando = false;
           });
         });
    });
  }

  cargarProyectoALMA() {
    this.listarProyectosALMA.length = 0;

    this.firestore.collection('proyectosALMA').get().subscribe((result) => {
         this.listarProyectosMTI = [];
         result.forEach((item: any) => {
         let inscripcionObtenida = item.data();
         inscripcionObtenida.id = item.id;

         ///TODO pedir informacion de un reference
         this.firestore.doc(item.data().categoria.path).get().subscribe((cliente) => {
              inscripcionObtenida.clienteObtenido = cliente.data();
              this.listarProyectosALMA.push(inscripcionObtenida);
              this.cargando = false;
           });
         });
    });
  }

  public getProyectoMTI(id: string) {
    return this.firestore.doc('proyectosMTI/' + id).valueChanges();
  }

  public getProyectoFOMAV(id: string) {
    return this.firestore.doc('proyectosFOMAV/' + id).valueChanges();
  }

  public getProyectoMINSA(id: string) {
    return this.firestore.doc('proyectosMINSA/' + id).valueChanges();
  }

  public getProyectoALMA(id: string) {
    return this.firestore.doc('proyectosALMA/' + id).valueChanges();
  }
}
