<div class="bradcam_area_proyecto bradcam_overlay_proyecto animated fadeIn">
    <div class="container animate__animated animate__rubberBand">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>Contacto</h3>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ================ contact section start ================= -->
<section class="contact-section animated fadeInUpBig top">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="contact-title">Contáctenos</h2>
            </div>
            <div class="col-lg-8">
                <form class="form-contact contact_form" [formGroup]="contactoForm" (ngSubmit)="enviarEmail(contactoForm.value)">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input class="form-control" formControlName="nombre" name="nombre" id="nombre" type="text" placeholder=" Persona o Empresa*" [class.is-invalid]="nombreNoValido" [class.is-valid]="nombreValido">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input class="form-control" formControlName="correo" name="email" id="email" type="email" placeholder=" Correo electronico*" [class.is-invalid]="correoNoValido" [class.is-valid]="correoValido">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <input class="form-control" formControlName="telefono" name="subject" id="subject" type="text" placeholder=" Teléfono*" [class.is-invalid]="telefonoNoValido" [class.is-valid]="telefonoValido">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <textarea class="form-control w-100" formControlName="mensaje" name="message" id="message" cols="30" rows="9" placeholder=" Mensaje*" [class.is-invalid]="mensajeNoValido" [class.is-valid]="mensajeValido"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <button class="btn boxed-btn3-white-3 btn-block" [disabled]="contactoForm.invalid"> <i class="fas fa-paper-plane"></i>&nbsp; &nbsp;Enviar</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 offset-lg-1" *ngFor="let informacion of datos">
                <div class="media contact-info">
                    <span class="contact-info__icon"><i class="ti-email"></i></span>
                    <div class="media-body">
                        <h3>Ubicación</h3>
                        <iframe class="lazyload" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.4515569962164!2d-86.28829770329357!3d12.149630505832931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f71567a970b5349%3A0x4ce3b4c57bba5aaf!2sCorea%20y%20Asociados%20S.%20A%20(CORASCO)!5e0!3m2!1ses-419!2sni!4v1619711277630!5m2!1ses-419!2sni" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
                <div class="media contact-info">
                    <span class="contact-info__icon"><i class="ti-home"></i></span>
                    <div class="media-body">
                        <h3> Dirección </h3>
                        <p> {{informacion.data.Direccion}}</p>
                    </div>
                </div>
                <div class="media contact-info">
                    <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                    <div class="media-body">
                        <h3> Telefonos </h3>
                        <p> {{informacion.data.Telefono}} </p>
                    </div>
                </div>
                <div class="media contact-info">
                    <span class="contact-info__icon"><i class="ti-email"></i></span>
                    <div class="media-body">
                        <h3>Email</h3>
                        <p>{{informacion.data.correo}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ================ contact section end ================= -->
